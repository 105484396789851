<template>
  <div></div>
</template>

<script>
import { computed } from 'vue'
import http from "@/utilities/http"
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  async created() {
    try {

      const message = await http.get("Message", { params: { id: this.id } })

      const childRoute = computed(() => {
        switch (message.type) {
          case "Message": return "Messages"
          case "Article": return "Articles"
          case "Guide": return "Guides"
          case "System": return "System"
          default: return ""
        }
      })

      if (childRoute.value) {
        this.$router.replace({
          name: `messages.${childRoute.value}`,
          params: {
            id: this.id
          }
        })
      }

    } catch {
      this.$notifyError("Fant ikke siden.")
    }
  }
}
</script>